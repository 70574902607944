import React, { Fragment } from 'react'

const Inicio = () => {
    
    return (
    	<Fragment>
    		<div className="main-wrapper">
	            <div className="header-section">
	                <div className="header-first"></div>
	                <h1>Cuestionario de ejemplo</h1>
	                <p>Hola, este es un ejemplo de un cuestionario que llevará a una sorpresa, contesta lo que gustes.</p>
	                <p id="required"> * Requerido</p>
	            </div>

	            <div className="main-section">

	                <div className="section-two">
	                    <p>¿Como te llamas? <span>*</span></p> <br />
	                    <input type="text" aria-label="First name" placeholder="Tu respuesta" className="form-control" required />
	                </div>

	                <div className="section-four">
	                    <p>¿Que prefieres? <span>*</span></p> <br />
	                    <div className="custom-control custom-radio">
	                        <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
	                        <label className="custom-control-label" for="customRadio1">Calor</label>
	                    </div> <br />
	                    <div className="custom-control custom-radio">
	                        <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" />
	                        <label className="custom-control-label" for="customRadio2">Frio</label>
	                    </div>
	                </div>

	                <div className="section-six">
	                    <p>Elige alguna de estas tres opciones: </p> <br />
	                    <div className="custom-control custom-radio">
	                        <input type="radio" id="customRadio3" name="customRadio1" className="custom-control-input" />
	                        <label className="custom-control-label" for="customRadio3">Duermo menos de 5 horas</label>
	                    </div>
	                    <br />
	                    <div className="custom-control custom-radio">
	                        <input type="radio" id="customRadio4" name="customRadio1" className="custom-control-input" />
	                        <label className="custom-control-label" for="customRadio4">Duermo de 6 a 8 horas</label>
	                    </div>
	                    <br />
	                    <div className="custom-control custom-radio">
	                        <input type="radio" id="customRadio6" name="customRadio1" className="custom-control-input" />
	                        <label className="custom-control-label" for="customRadio6">Duermo más de 8 horas</label>
	                    </div>
	                </div>

	                <div className="section-nine">
	                    <p>¿Cual es tu canción favorita? <span>*</span></p>
	                    <br />
	                    <input type="text" aria-label="First name" placeholder="Tu respuesta" className="form-control" required />
	                </div>

	            </div>

	            <div className="footer-section">

	                <div className="content row d-flex mb-3">
	                    <div className="left-item col-sm-6  order-sm-12">
	                        <div className="line"></div>
	                        <p className="text ml-2 pb-2">Página 1 de 1</p>
	                    </div>

	                    <div className="col-sm-6  order-sm-1">
	                        <a className="btn btn-fake btn-primary" href="/sorpresa">Enviar</a>
	                    </div>
	                </div>
	            </div>
            </div>
    	</Fragment>
    )
}

export default Inicio;