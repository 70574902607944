import React, { Fragment } from 'react'

const FooterEncuesta = () => {
    return (
        <Fragment>
            <div className="footer">
                <p className="text-center parrafo-footer">Este contenido no ha sido creado ni aprobado por Google.<a href="#"><span>Notificar uso inadecuado </span> - </a><a href="#"><span>Términos del Servicio</span> - </a><a href="#"> <span>Política de Privacidad</span> </a></p>
            </div>
            <div className="mb-4">
                <h1 className="text-center foot1">
                    <span className="logo-parte-1">Google</span>
                    <span className="logo-parte-2"> Formularios</span>
                </h1>
            </div>
        </Fragment>
    )
}

export default FooterEncuesta;