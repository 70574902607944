import React, { Fragment } from 'react'

const Sorpresa = () => {
    
    return (
    	<Fragment>
			<div className="container centrado-ambos">
				<div className="card">
				  	<div className="card-body text-center">
					    <h1 className="card-title">¿Te gustaría ir al cine conmigo?</h1>
				  	</div>
				</div>
				<div className="row mt-5">
					<div className="col-6 text-center">
						<button id="boton-si" type="button" className="btn btn-success btn-lg btn-block">Si</button>
						<img id="imagen-si" className="img-fluid mt-3 floater" src="/assets/img/dogge.png" alt="" />
					</div>
					<div className="col-6 text-center">
						<button id="boton-no" type="button" className="btn btn-danger btn-lg btn-block">No</button>
						<img id="imagen-no" className="img-fluid mt-3 floater" src="/assets/img/chems.png" alt="" />
					</div>
				</div>
			</div>
    	</Fragment>
    )
}

export default Sorpresa;