import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'
import Sorpresa from './Views/Sorpresa'

import FooterEncuesta from './Components/Layout/FooterEncuesta'

function App() {
  return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Inicio />
                    <FooterEncuesta />
                </Route>
                <Route exact path="/sorpresa">
                    <Sorpresa />
                </Route>
                <Route path="/">
                    <Inicio />
                    <FooterEncuesta />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
